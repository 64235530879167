import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-01-19 10:18:50
 */
//优惠券分页查询
export function getCouponList(parameter){
    return axios({
        url: '/coupon/page',
        method: 'post',
        params:parameter.page,
        data: parameter
    })
}
//优惠券新增
export function couponAdd(parameter){
    return axios({
        url: '/coupon/add',
        method: 'post',
        data: parameter
    })
}
//优惠券编辑
export function couponEdit(parameter){
    return axios({
        url: '/coupon/edit',
        method: 'post',
        data: parameter
    })
}
//优惠券删除（正常，禁用）
export function couponDelete(parameter){
    return axios({
        url: '/coupon/delete',
        method: 'post',
        data: parameter
    })
}
//优惠券详情
export function couponDetail(parameter){
    return axios({
        url: '/coupon/detail',
        method: 'post',
        params: parameter
    })
}