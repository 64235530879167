<template>
  <a-modal
    title="详情"
    width='50%'
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <a-spin :spinning="confirmLoading">
      <a-row>
        <a-form layout="inline">
          <a-col>
            <!-- 第1行详情 -->
            <a-row>
              <a-col :md="12" :sm="24">
                <a-form-item label="优惠券名称">
                  <span>{{ details.title }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="是否需要关注店铺">
                  <span>{{ details.isFollowCoupon == 1 ? '否' : details.isFollowCoupon == 2 ? '是' : '' }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 第2行详情 -->
            <a-row>
              <a-col :md="12" :sm="24">
                <a-form-item label="优惠券类型">
                  <span>{{ details.type == 1 ? '满减' : details.type == 2 ? '直减' : '' }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="优惠方式">
                  <span v-show="details.type == 1">满{{ details.fullPrice }}，</span
                  ><span>减{{ details.minusPrice }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="优惠券数量">
                  <span>{{ details.num }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 第3行详情 -->
            <a-row>
              <a-col :md="12" :sm="24">
                <a-form-item label="优惠券有效期">
                  <span v-if="details.timeType == 1">起：{{ details.beginTime }}<br />止：{{ details.endTime }}</span>
                  <span v-else-if="details.timeType == 2">领取{{ details.days }}日内有效</span>
                  <span v-else-if="details.timeType == 3">不限时</span>
                  <span v-else></span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="每人限领数量">
                  <span>{{ details.limitNum }}</span>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-form>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { couponDetail } from '@/api/modular/mallLiving/coupon/couponList'

export default {
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      id: '',
      details: {},
      form: this.$form.createForm(this),
    }
  },
  methods: {
    //初始化方法
    detail(record) {
      this.visible = true
      this.confirmLoading = true
      //获取详情数据
      setTimeout(() => {
        couponDetail({ id: record.id }).then((res) => {
          if (res.success) {
            this.details = res.data
            this.confirmLoading = false
          }
        })
      }, 100)
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.confirmLoading = false
    },
  },
}
</script>
<style lang="less" scoped>
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;
  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }
  .con {
    width: 30%;
  }
  td {
    border: 2px solid rgb(228, 225, 225);
    padding-left: 7px;
    font-size: 15px;
  }
}
img {
  width: 100px;
  height: 50px;
}
</style>
