<template>
  <a-modal
    title="添加优惠券"
    :width="750"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-row>
          <a-col :span="24">
            <a-form-item label="优惠券名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                allow-clear
                placeholder="输入内容"
                v-decorator="['title', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <!--     是否需要关注店铺         -->
            <a-form-item label="是否需要关注店铺" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-radio-group name="radioGroup" v-model="isFollowCoupon" default-value="1">
                <a-radio value="2">是</a-radio>
                <a-radio value="1">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-col :span="24">
              <a-form-item label="优惠券类型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-select
                  v-decorator="['type', { rules: [{ required: true, message: '请选择！' }] }]"
                  allow-clear
                  placeholder="请选择"
                >
                  <a-select-option v-for="(item, index) in couponTypeList" :key="index" :value="item.status">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-col>
        </a-row>
        <a-row :gutter="5">
          <a-col :span="11" v-if="this.form.getFieldValue('type') == 1">
            <a-form-item label="满（元）" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
              <a-input-number
                :min="0.01"
                :precision="2"
                style="width: 100%"
                v-decorator="['fullPrice', { rules: [{ required: true, message: '请输入！' }] }]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-col>

          <a-col :span="11">
            <a-form-item label="减（元）" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
              <a-input-number
                :min="0.01"
                :precision="2"
                style="width: 100%"
                v-decorator="['minusPrice', { rules: [{ required: true, message: '请输入！' }] }]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="优惠券数量" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number
                placeholder="请输入数量"
                :precision="0"
                allow-clear
                :min="0"
                style="width: 100%"
                v-decorator="['num', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="3">
          <a-col :span="11">
            <a-form-item label="优惠券有效期" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
              <a-select
                allow-clear
                placeholder="请选择"
                v-decorator="['timeType', { rules: [{ required: true, message: '请选择！' }] }]"
                style="width: 160px"
              >
                <a-select-option v-for="(item, index) in couponLifetimeList" :key="index" :value="item.status">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8" :offset="1">
            <a-form-item has-feedback>
              <!-- timeType==1 -->
              <a-range-picker
                v-if="this.form.getFieldValue('timeType') == 1"
                allow-clear
                v-decorator="['lifetimeList', { rules: [{ required: true, message: '请选择！' }] }]"
              />
              <!-- timeType==2 -->
              <a-input-number
                v-else-if="this.form.getFieldValue('timeType') == 2"
                :precision="0"
                :min="0"
                placeholder="请输入天数"
                style="width: 60%"
                v-decorator="['days', { rules: [{ required: true, message: '请输入天数！' }] }]"
              />
              <!-- timeType==3 -->
              <span v-else></span>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col>
            <a-form-item label="每人限领数目" :labelCol="labelCol" :wrapperCol="wrapperCol" allow-clear has-feedback>
              <a-input-number
                placeholder="请输入优惠券每人限领数目"
                allow-clear
                :precision="0"
                :min="0"
                style="width: 100%"
                v-decorator="['limitNum', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { couponAdd } from '@/api/modular/mallLiving/coupon/couponList'

export default {
  data() {
    return {
      loading: false,
      queryParam: {},
      couponType: undefined,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      labelCol_1: {
        xs: { span: 24 },
        sm: { span: 11 },
      },
      wrapperCol_1: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      couponTypeList: [
        { status: 1, name: '满减' },
        { status: 2, name: '直减' },
      ],
      couponLifetimeList: [
        { status: 1, name: '指定日期范围内有效' },
        { status: 2, name: '领取N天内有效' },
        { status: 3, name: '不限时' },
      ],
      isFollowCoupon: '1',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },

  methods: {
    // 初始化方法
    add() {
      this.visible = true
      // setTimeout(() => {
      //   //设置初始化值
      //   this.form.setFieldsValue({

      //   })
      // }, 100)
    },
    // 确定按钮操作
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 如果时间范围存在
          if (values.lifetimeList) {
            values.beginTime = this.onChange(values.lifetimeList[0]._d)
            values.endTime = this.onChange(values.lifetimeList[1]._d)
            delete values.lifetimeList
          }
          //如果优惠价格高于原价
          if (values.fullPrice && values.fullPrice < values.minusPrice) {
            this.$message.error('优惠价格不可高于原价')
            this.confirmLoading = false
            return
          }
          //如果每人限领数目高于优惠券数量
          if (values.num < values.limitNum) {
            this.$message.error('每人限领数目不可高于优惠券数量')
            this.confirmLoading = false
            return
          }
          values.isFollowCoupon = this.isFollowCoupon

          couponAdd(values)
            .then((res) => {
              if (res.success) {
                this.$message.success('添加成功')
                this.$emit('ok')
                this.handleCancel()
              }
            })
            .finally((res) => {
              setTimeout(() => {
                this.confirmLoading = false
              }, 500)
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
    //时间处理函数
    onChange(date) {
      var date = new Date(date)
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return year + '-' + month + '-' + day + ' 00:00:00'
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
